<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${statusArticleColor};`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 class="date" :style="`color: ${statusTime};`">
          조회시간: {{ str_time }}
        </h5>
        <button
          class="btn_sub1 refresh_btn"
          @click="refreshData"
          :style="
            `background-color: ${statusBtnColor}; color: ${statusBtnFontColor};`
          "
        >
          재조회
        </button>
        <button
          class="btn_sub1"
          @click="fullScreenFn"
          :style="
            `background-color: ${statusBtnColor}; color: ${statusBtnFontColor};`
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide
          class="mes_tbl_wrap swiper-slide"
          v-for="(items, index) in filterMaterialList"
          :style="`border: 2px solid ${statusMesborTbl}`"
          :key="index"
        >
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  colspan="9"
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                  "
                >
                  원물 재고현황
                </th>
              </tr>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                  "
                >
                  원물
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                  "
                >
                  규격
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                  "
                >
                  전재고
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                  "
                >
                  전재고(box)
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                  "
                >
                  수(단위)
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                  "
                >
                  불(단위)
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                  "
                >
                  현재고
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                  "
                >
                  현재고(box)
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                  "
                >
                  보관 장소
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(stock, index2) in items" :key="stock.id">
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 0; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                      : `border-left: 0; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                  "
                >
                  {{ stock.name }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                      : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                  "
                >
                  {{ stock.standard }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                      : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                  "
                >
                  {{ stock.beforeStock_unit }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                      : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                  "
                >
                  {{ stock.beforeStock_box }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                      : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                  "
                >
                  {{ stock.inStock }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                      : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                  "
                >
                  {{ stock.outStock }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                      : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                  "
                >
                  {{ stock.nowStock_unit }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                      : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                  "
                >
                  {{ stock.nowStock_box }}
                </td>
                <td
                  :style="
                    items.length - 1 == index2
                      ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                      : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                  "
                >
                  {{ stock.storeLocation }}
                </td>
              </tr>
            </tbody>
          </table>
        </swiper-slide>
        <!-- </div> -->
      </swiper>
      <div class="slide_btn_wrap" v-show="filterMaterialList.length != 0">
        <button
          type="button"
          class="button-prev"
          :style="`background-color: ${statusSlideBtnColor};`"
        >
          <i
            class="fa fa-angle-left"
            :style="` color: ${statusSlideBtniColor};`"
          ></i>
        </button>
        <button
          type="button"
          class="button-play-stop"
          :class="{ active: playStop }"
          @click.prevent.stop="autoPlay"
        ></button>
        <button
          type="button"
          class="button-next"
          :style="`background-color: ${statusSlideBtnColor};`"
        >
          <i
            class="fa fa-angle-right"
            :style="` color: ${statusSlideBtniColor};`"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import fetchMixin from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [SWIPER_MIXIN, fetchMixin],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,

      statusTime: localStorage.getItem('statusTime'),
      statusArticleColor: localStorage.getItem('statusArticleColor'),
      statusMesborTbl: localStorage.getItem('statusMesborTbl'),
      statusMesboriTbl: localStorage.getItem('statusMesboriTbl'),
      statusTempbgColor: localStorage.getItem('statusTempbgColor'),
      statusTempFont: localStorage.getItem('statusTempFont'),
      statusDivColor: localStorage.getItem('statusDivColor'),
      statusDivFont: localStorage.getItem('statusDivFont'),
      statusTableColor: localStorage.getItem('statusTableColor'),
      statusDetailFont: localStorage.getItem('statusDetailFont'),
      statusDetailTempFont: localStorage.getItem('statusDetailTempFont'),
      statusBtnColor: localStorage.getItem('statusBtnColor'),
      statusBtnFontColor: localStorage.getItem('statusBtnFontColor'),
      statusSlideBtnColor: localStorage.getItem('statusSlideBtnColor'),
      statusSlideBtniColor: localStorage.getItem('statusSlideBtniColor'),
    };
  },
  computed: {
    ...mapGetters({
      materials: 'getMaterial', // 규격 확인용
      materialTotalStock: 'getMaterialTotalStock', // 현재 총 재고량 확인용
      stockStatusList: 'getMaterialStockStatusListFromMaterialStockStatus', //  수불 확인용
      materialControlList: 'getMaterialControl', // 전 재고, 재고창고 확인용
      materialInOutType: 'getMaterialInOutType',
      stores: 'getStores',
      unitCodes: 'getUnitCodes',
      resourceTypes: 'getResourceType',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filterMaterialList() {
      let stockList = [];

      if (
        this.materialTotalStock.length > 0 &&
        this.materialInOutType.length > 0
      ) {
        // 현재 재고
        let materialTotalStockClone = this.lodash
          .clonedeep(this.materialTotalStock)
          .filter(
            x =>
              this.findInfoFromId(this.materials, x.material_id)
                .resource_type_id ==
                this.resourceTypes.find(y => y.detail == 'raw_material').id &&
              x.stock_quantity > 0,
          )
          .sort((a, b) => a.material_id - b.material_id);

        materialTotalStockClone.forEach(el => {
          let materialInfo = this.findInfoFromId(
            this.materials,
            el.material_id,
          );
          let foundLastMaterialControl = this.filterStoreLocation.filter(
            x => x.material_id == el.material_id,
          );
          const lastStoreId =
            foundLastMaterialControl.length > 0
              ? foundLastMaterialControl[0].store_id
              : null;

          let beforeStock = el.stock_quantity;
          let inStockSum = 0;
          let outStockSum = 0;

          if (this.filterStockStatusList[0].length > 0) {
            inStockSum = this.filterStockStatusList[0]
              .filter(
                x =>
                  x.material_id == el.material_id &&
                  x.material_in_out_type_id ==
                    this.materialInOutType.find(y => y.detail == 'buy in').id,
              )
              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            outStockSum = this.filterStockStatusList[0]
              .filter(
                x =>
                  x.material_id == el.material_id &&
                  (x.material_in_out_type_id ==
                    this.materialInOutType.find(y => y.detail == 'using out')
                      .id ||
                    x.material_in_out_type_id ==
                      this.materialInOutType.find(y => y.detail == 'return out')
                        .id),
              )

              .map(x => x.quantity)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            beforeStock = this.$decimalSub(
              this.$decimalAdd(el.stock_quantity, outStockSum),
              inStockSum,
            );
          }

          stockList.push({
            id: materialInfo.id,
            name: materialInfo.name,
            standard: materialInfo.standard,
            beforeStock_unit:
              this.$makeComma(beforeStock) +
              '(' +
              this.findInfoFromId(this.unitCodes, materialInfo.incoming_unit_id)
                .name +
              ')',
            beforeStock_box:
              materialInfo.order_unit == 0
                ? 0
                : this.$makeComma(
                    Math.floor(
                      this.$decimalDiv(beforeStock, materialInfo.order_unit),
                    ),
                  ),
            inStock:
              this.$makeComma(inStockSum) +
              '(' +
              this.findInfoFromId(this.unitCodes, materialInfo.incoming_unit_id)
                .name +
              ')',
            outStock:
              this.$makeComma(outStockSum) +
              '(' +
              this.findInfoFromId(this.unitCodes, materialInfo.using_unit_id)
                .name +
              ')',
            nowStock_unit:
              this.$makeComma(el.stock_quantity) +
              '(' +
              this.findInfoFromId(this.unitCodes, materialInfo.incoming_unit_id)
                .name +
              ')',
            nowStock_box:
              materialInfo.order_unit == 0
                ? 0
                : this.$makeComma(
                    Math.floor(
                      this.$decimalDiv(
                        el.stock_quantity,
                        materialInfo.order_unit,
                      ),
                    ),
                  ),
            storeLocation:
              lastStoreId == null
                ? '-'
                : this.findInfoFromId(this.stores, lastStoreId).name,
          });
        });
      }
      let arr = [];
      if (this.fullScreen) {
        stockList.forEach((element, index) => {
          if (index % 9 == 0) {
            arr.push(stockList.slice(index, index + 9));
          }
        });
      } else {
        stockList.forEach((element, index) => {
          if (index % 5 == 0) {
            arr.push(stockList.slice(index, index + 5));
          }
        });
      }
      return arr;
    },

    filterStockStatusList() {
      let stockStatusClone = this.lodash.clonedeep(this.stockStatusList);

      if (this.resourceTypes.length > 0) {
        stockStatusClone = stockStatusClone.filter(
          x =>
            this.findInfoFromId(this.materials, x.material_id)
              .resource_type_id ==
            this.resourceTypes.find(y => y.detail == 'raw_material').id,
        );

        let today = new Date();
        today = yyyymmdd(today);

        const todayList = this.lodash
          .clonedeep(stockStatusClone)
          .filter(x => x.create_time.substr(0, 10) == today);

        const beforeList = this.lodash
          .clonedeep(stockStatusClone)
          .filter(x => x.create_time.substr(0, 10) != today);

        return [todayList, beforeList];
      } else {
        return [];
      }
    },
    filterStoreLocation() {
      let storeLoctionClone = this.lodash.clonedeep(this.materialControlList);

      if (this.materialControlList.length > 0) {
        storeLoctionClone = storeLoctionClone.sort(
          (b, a) =>
            Number(
              a.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        );
      }
      return storeLoctionClone;
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_MATERIAL_CONTROL', '재고 관리');
      await this.FETCH_NO_SPINNER('FETCH_MATERIAL_STATUS', '원자재 현황');
      await this.FETCH_NO_SPINNER('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    if (this.stores.length < 1) {
      await this.FETCH('FETCH_STORE', '창고 리스트');
    }
    if (this.resourceTypes.length < 1) {
      await this.FETCH('FETCH_RESOURCE_TYPE', '자원유형');
    }

    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_MATERIAL_STATUS', '자재 현황');

    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());

    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }

    if (this.unitCodes.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }

    if (this.materialInOutType.length < 1) {
      await this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE', '자재 입출고 유형');
    }

    await this.FETCH('FETCH_MATERIAL_CONTROL', '재고 관리');
    await this.FETCH('FETCH_MATERIAL_STATUS', '원자재 현황');
    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_MATERIAL_STATUS', '자재 현황');
        await this.FETCH_NO_SPINNER('FETCH_MATERIAL_CONTROL', '재고 관리');
        await this.FETCH_NO_SPINNER('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    // if (this.swiper != undefined) {
    //   this.swiper.slideTo(1, 0, false);
    // }
  },
};
</script>

<style lang="scss" scoped></style>
