<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${statusArticleColor};`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5 :style="`color: ${statusTime};`">조회시간: {{ date }}</h5>
        <button
          class="btn_sub1 refresh_btn"
          @click="refreshData"
          :style="
            `background-color: ${statusBtnColor}; color: ${statusBtnFontColor};`
          "
        >
          재조회
        </button>
        <button
          class="btn_sub1"
          @click="fullScreenFn"
          :style="
            `background-color: ${statusBtnColor}; color: ${statusBtnFontColor};`
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <swiper
        ref="mySwiperRef"
        class="swiper-container"
        :options="swiperOption"
      >
        <swiper-slide
          class="mes_tbl_wrap swiper-slide"
          v-for="(items, index) in filteredRequest"
          :key="index"
        >
          <div
            class="auto_width"
            :style="`border: 2px solid ${statusMesborTbl}`"
          >
            <table class="mes_tbl">
              <thead>
                <tr>
                  <th
                    colspan="8"
                    :style="
                      `border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                    "
                  >
                    <span>출고 요청서</span>
                  </th>
                </tr>
                <tr>
                  <th
                    :style="
                      `border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                    "
                  >
                    품목
                  </th>
                  <th
                    :style="
                      `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                    "
                  >
                    납품처
                  </th>
                  <th
                    :style="
                      `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                    "
                  >
                    품목(단위)
                  </th>
                  <th
                    :style="
                      `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl}; background-color: ${statusTempbgColor}; color: ${statusTempFont};`
                    "
                  >
                    수량(단위)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(request, index2) in items" :key="request.id">
                  <td
                    :style="
                      items.length - 1 == index2
                        ? `border-left: 0; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                        : `border-left: 0; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                    "
                  >
                    {{ findInfoFromId(products, request.product_id).name }}
                  </td>
                  <td
                    :style="
                      items.length - 1 == index2
                        ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                        : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                    "
                  >
                    {{
                      findInfoFromId(
                        companys,
                        findInfoFromId(sales, request.sales_id).company_id,
                      ).name
                    }}
                  </td>
                  <td
                    :style="
                      items.length - 1 == index2
                        ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                        : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                    "
                  >
                    {{ request.standard }}
                  </td>
                  <td
                    :style="
                      items.length - 1 == index2
                        ? `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 0; background-color: ${statusTableColor}; color: ${statusDetailFont};`
                        : `border-left: 2px solid ${statusMesboriTbl}; border-bottom: 2px solid ${statusMesboriTbl};
                    background-color: ${statusTableColor}; color: ${statusDetailFont};`
                    "
                  >
                    {{
                      `${$makeComma(request.quantity)}(${
                        findInfoFromId(
                          units,
                          findInfoFromId(products, request.product_id)
                            .stock_unit_id,
                        ).name
                      })`
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </swiper-slide>
      </swiper>
      <div class="slide_btn_wrap" v-show="filteredRequest.length != 0">
        <button
          type="button"
          class="button-prev"
          :style="`background-color: ${statusSlideBtnColor};`"
        >
          <i
            class="fa fa-angle-left"
            :style="` color: ${statusSlideBtniColor};`"
          ></i>
        </button>
        <button
          type="button"
          class="button-play-stop"
          :class="{ active: playStop }"
          @click.prevent="autoPlay()"
        ></button>
        <button
          type="button"
          class="button-next"
          :style="`background-color: ${statusSlideBtnColor};`"
        >
          <i
            class="fa fa-angle-right"
            :style="` color: ${statusSlideBtniColor};`"
          ></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import FETCH_MIXIN from '@/mixins/fetch';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import { mapGetters } from 'vuex';

export default {
  mixins: [SWIPER_MIXIN, FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      date: yyyy년MM월dd일HH시mm분ss초(new Date()),
      timerCount: 60,
      timerMax: 60,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,

      statusTime: localStorage.getItem('statusTime'),
      statusArticleColor: localStorage.getItem('statusArticleColor'),
      statusMesborTbl: localStorage.getItem('statusMesborTbl'),
      statusMesboriTbl: localStorage.getItem('statusMesboriTbl'),
      statusTempbgColor: localStorage.getItem('statusTempbgColor'),
      statusTempFont: localStorage.getItem('statusTempFont'),
      statusDivColor: localStorage.getItem('statusDivColor'),
      statusDivFont: localStorage.getItem('statusDivFont'),
      statusTableColor: localStorage.getItem('statusTableColor'),
      statusDetailFont: localStorage.getItem('statusDetailFont'),
      statusDetailTempFont: localStorage.getItem('statusDetailTempFont'),
      statusBtnColor: localStorage.getItem('statusBtnColor'),
      statusBtnFontColor: localStorage.getItem('statusBtnFontColor'),
      statusSlideBtnColor: localStorage.getItem('statusSlideBtnColor'),
      statusSlideBtniColor: localStorage.getItem('statusSlideBtniColor'),
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      sales: 'getSalesFromOutputRequest',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filteredRequest() {
      if (this.sales != undefined) {
        let salesClone = this.lodash.clonedeep(this.sales);
        try {
          let filterClone = salesClone
            .map(x => x.product_list)
            .reduce((a, b) => a.concat(b))
            .sort((a, b) => b.id - a.id)
            .filter(x => x.request_out);
          let arr = [];
          if (this.fullScreen) {
            filterClone.forEach((element, index) => {
              if (index % 9 == 0) {
                arr.push(filterClone.slice(index, index + 9));
              }
            });
          } else {
            filterClone.forEach((element, index) => {
              if (index % 5 == 0) {
                arr.push(filterClone.slice(index, index + 5));
              }
            });
          }
          return arr;
        } catch (error) {
          return [];
        }
      } else {
        return '';
      }
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
      this.date = yyyy년MM월dd일HH시mm분ss초(new Date());
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    await this.FETCH('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 2) {
        await this.FETCH_NO_SPINNER('FETCH_SALES_TO_OUTPUT_REQUEST', '입출고');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.date = yyyy년MM월dd일HH시mm분ss초(new Date());
    // if (this.swiper != undefined) {
    //   this.swiper.slideTo(1, 0, false);
    // }
  },
};
</script>

<style lang="scss" scoped></style>
